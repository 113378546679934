import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useBaseTheme } from 'Hooks'

import { Container, MapContainer, ResultsContainer } from './styles'
import Map from '../Map'

const SearchResultMap = ({
  activeMapResult,
  children,
  innerRef,
  searchResults,
  onActiveMarkerChange,
  ...rest
}) => {
  const { primaryColor, isLargeScreen, isSearchByServiceEnabled } =
    useBaseTheme()

  const results = get(searchResults, 'data') || []
  const isPaginated = get(rest, 'paginated', false)

  let mobileHeightOffset = isPaginated ? '200px' : '160px'

  // Account for the search type switch if visible
  if (isSearchByServiceEnabled) {
    mobileHeightOffset = isPaginated ? '250px' : '210px'
  }

  // Mobile and no results
  if (!isLargeScreen && !results?.length) {
    mobileHeightOffset = '175px'
  }

  const handleMarkerClick = marker => {
    onActiveMarkerChange(marker)
  }

  const getMapCenterFallback = useMemo(() => {
    const geoBuckets =
      searchResults?.geographical_buckets ||
      searchResults?.meta?.geographical_buckets ||
      {}

    if (geoBuckets.suburb) {
      return geoBuckets.suburb.lat_lng
    }

    return geoBuckets.region?.lat_lng
  }, [searchResults])

  return (
    <Container mobileoffset={mobileHeightOffset} {...rest}>
      <ResultsContainer
        activecolor={primaryColor}
        className="search-results-container"
        p={
          (isLargeScreen && results?.length) ||
          (!isLargeScreen && !results?.length)
            ? '5px'
            : '0'
        }
        ref={innerRef}
      >
        {children}
      </ResultsContainer>
      <MapContainer>
        {searchResults && (
          <Map
            activeMarker={activeMapResult}
            center={getMapCenterFallback}
            markers={results}
            showMarkerCard={false}
            onMarkerCardClose={() => {}}
            onMarkerClick={handleMarkerClick}
          />
        )}
      </MapContainer>
    </Container>
  )
}

SearchResultMap.defaultProps = {
  activeMapResult: null,
  children: null,
  innerRef: null,
  searchResults: null,
}

SearchResultMap.propTypes = {
  activeMapResult: PropTypes.object,
  children: PropTypes.any,
  innerRef: PropTypes.object,
  searchResults: PropTypes.object,
  onActiveMarkerChange: PropTypes.func.isRequired,
}

export default SearchResultMap
